import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        bgcolor: '#0F1214',
        color: 'white',
        minHeight: '100vh', // Ensures it fills the viewport height
        py: 4, // Adds padding for better spacing
        minWidth:'100%'
      }}
    >
      <Typography variant="h3" component="h1" textAlign="center" gutterBottom sx={{ fontWeight: 'bold' }}>
        FAQ
      </Typography>
      {[
        {
          question: 'What is the Solicitors Qualification Exam?',
          answer:
            'The Solicitors Qualification Exam (SQE) is the new form of assessment put forward by the Solicitor’s Regulatory Authority. It consists of two assessments, called Functioning legal knowledge assessments (FLK 1 and FLK 2). Each exam consists of 180 questions and is sat in a Person test center.',
        },
        {
          question: 'What is the Law School Compass?',
          answer:
            'We are a private tuition company which aims to provide accessible tutoring at an affordable price. The main point being, we’ve been in the position you are in so we know how to prepare for your SQE1 exams.',
        },
        {
          question: 'Is Law School Compass right for me?',
          answer:
            'If you are preparing for the SQE (whether SQE1 or SQE2), we are here to support you. Whether you are studying independently or enrolled with a course provider, our materials are carefully crafted to provide you with unique learning tools. We’re not your typical teachers or professors. We are ready to help you not only succeed but also enjoy the journey to conquering the SQE.',
        },
        {
          question: 'What is QWE?',
          answer:
            'Qualifying Work Experience (QWE) is a key requirement for becoming a solicitor in England and Wales under the Solicitors Qualifying Examination (SQE) route. It involves gaining practical legal experience in one or more organisations for a minimum of two years. The experience can be completed at law firms, in-house legal teams, or even through pro bono work, as long as it provides exposure to legal practice and is signed off by an authorized solicitor. Unlike traditional training contracts, QWE offers more flexibility.',
        },
        {
          question: 'What is your cancellation policy for tuition services?',
          answer:
            'We expect clients to cancel or reschedule their sessions with at least 48 hours notice. Sessions which are canceled with less than 48 hours notice may not be eligible for a refund. Terms and conditions apply.',
        },
        {
          question: 'What are your mocks?',
          answer: 'We offer both virtual and in-person SQE mocks as part of our tuition services.',
        },
        {
          question: 'How do I contact you?',
          answer: 'You can contact us via email at info.admin@lawschoolcompass.com.',
        },
      ].map((faq, index) => (
        <Accordion
          key={index}
          defaultExpanded
          sx={{
            bgcolor: '#14181B', // Dark background
            color: 'white', // White text
            borderRadius: '8px', // Slightly rounded corners
            mb: 2, // Margin between accordions
            '& .MuiAccordionSummary-root': {
              bgcolor: '#1C1F23', // Slightly lighter background for the summary
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white', borderRadius: '8px' }} />}>
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default FAQ;
