import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/School';
import DescriptionIcon from '@mui/icons-material/Description';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import RevampNavBar from './RevampNavBar';

const SoftSkillsPage = () => {
    const services = [
        {
            icon: <DescriptionIcon sx={{ fontSize: 50, color: 'white' }} />,
            title: "CV Review",
            description: "Submit your CV and receive tailored feedback within 72 hours for just £8 per CV. Feedback will be delivered via email: info.admin@lawschoolcompass.com"
        },
        {
            icon: <ContactMailIcon sx={{ fontSize: 50, color: 'white' }} />,
            title: "Cover Letter Review",
            description: "Get personalized feedback on your cover letter within 72 hours for £10 per cover letter. Feedback will be sent to you via email: info.admin@lawschoolcompass.com"
        },
        {
            icon: <DescriptionIcon sx={{ fontSize: 50, color: 'white' }} />,
            title: "Personal Statement Review",
            description: "Submit your personal statement for tailored feedback within 72 hours at £10 per statement. Feedback will be provided via email: info.admin@lawschoolcompass.com"
        },
        {
            icon: <VideoCallIcon sx={{ fontSize: 50, color: 'white' }} />,
            title: "Mock Interviews (via Zoom)",
            description: "We offer mock interview sessions tailored to your specific legal career goals, with detailed feedback within 48 hours. Paralegal Mock Interview: 30 minutes: £15, 1 hour: £25. Trainee Solicitor Mock Interview: 30 minutes: £15, 1 hour: £25."
        },
        {
            icon: <SupportAgentIcon sx={{ fontSize: 50, color: 'white' }} />,
            title: "Career Advice & Support",
            description: "Book a 30-minute Career Discussion session for £10, where we provide expert guidance on career progression, interview strategies, and more. To book, email us at info.admin@lawschoolcompass.com"
        },
    ];

    return (
        <Box sx={{ bgcolor: '#0F1214', color: 'white', minHeight: '100vh'}}>

            <br/>
            {/* Heading and Description */}
        
            <Typography variant="h3" component="h1" textAlign="center" gutterBottom sx={{fontWeight:'bold'}}>
                Soft Skills Services
            </Typography>
            <Typography variant="h6" textAlign="center" sx={{ mb: 6, pl:3,pr:3 }}>
                We understand that starting or advancing your legal career can be challenging. To help you prepare for your next step, we offer the following personalised services:
            </Typography>

            {/* Services Cards */}
            <Grid container spacing={4} justifyContent="center" sx={{pl:3,pr:3}}>
                {services.map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card sx={{ textAlign: 'center', borderRadius: '20px',p: 3, boxShadow: 5, backgroundImage: 'linear-gradient(to bottom right, #004AAD, #0064EA, #004AAD, #004AAD)', color: '#004AAD' }}>
                            <CardContent>
                                {service.icon}
                                <Typography variant="h6" sx={{ mt: 2, color:'white' }}>
                                    {service.title}
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 1, color:'white' }}>
                                    {service.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default SoftSkillsPage;
