import React,  { useState, useEffect } from 'react';
import { AppBar, Container, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { Instagram, YouTube, WhatsApp, Email } from '@mui/icons-material';

function Footer() {

  const [footerMargin, setFooterMargin] = useState("0");

  useEffect(() => {
    const handleResize = () => {
      const contentHeight = document.documentElement.scrollHeight;
      const viewportHeight = window.innerHeight;
      const margin = Math.max(viewportHeight - contentHeight, 0) + "px";
      setFooterMargin(margin);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Call initially for proper setup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <AppBar position="static" style={{mb: footerMargin, bottom:0,backgroundColor:"#004AAD", boxShadow: "0 -5px 7px rgba(0, 0, 0, 0.2)"}} >
      <Container>
        <Toolbar disableGutters>
          <Grid container justifyContent="center" alignItems="center" spacing={0}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1" align="center" sx={{ flexGrow: 1, marginTop:'15px', alignItems:"center", textAlign:'center', marginBottom:'9px' }}>
                Explore more:
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12} sx={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
              <div style={{ padding:'0.7%', display: 'flex', justifyContent: 'center', alignItems:"center", border:'2px solid white', width:'240px',}}>
              <IconButton sx={{color:'black', ':hover':{bgcolor:'black', color:'white',}}} onClick={()=>{window.open('https://www.youtube.com/channel/UCRH-j1jRN1kO_8dTLgQd39w/about', '_blank')}}>
                  <YouTube fontSize="large" sx={{}}/>
                </IconButton>
                <IconButton sx={{color:'black', ':hover':{bgcolor:'black', color:'white',}}} onClick={()=>{window.open('https://www.instagram.com/law.school.compass?igsh=MW02cTB0NGRiMnhhaQ==','_blank')}}>
                  <Instagram fontSize="large"/>
                </IconButton>
                <IconButton sx={{color:'black', ':hover':{bgcolor:'black', color:'white',}}} onClick={()=>{window.open('https://chat.whatsapp.com/G2NudXnOBhmDH04RTwq9lW', '_blank')}}>
                  <WhatsApp fontSize='large'/>
                </IconButton>
                <IconButton sx={{color:'black', ':hover':{bgcolor:'black', color:'white',}}} onClick={()=>{window.open('mailto:info.admin@lawschoolcompass.com', '_blank')}}>
                  <Email fontSize='large'/>
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
        <Typography variant="body2" align="center" style={{ color: 'white', padding: '10px' }}>
        
          <Typography variant="body2" component="span" style={{ fontWeight: 'bold' }}> Law School Compass </Typography>

        </Typography>
        <Typography variant="body2" align="center" style={{ color: 'white', padding: '2px' }}>Est. 2024</Typography>

      </Container>
    </AppBar>
  );
}

export default Footer;
