import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Box, Typography, Button, Divider, Card, CardContent, Rating } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SavingsIcon from '@mui/icons-material/Savings';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WorkIcon from '@mui/icons-material/Work';
import RevampNavBar from './RevampNavBar';

import girlStudyingPolygon from './studying_polygon.png';

// Steps for the custom stepper
const STEPS = [
  {
    icon: <SchoolIcon sx={{ fontSize: 40, color: '#004AAD' }} />,
    title: 'Level 6 Degree',
    description: 'Complete a qualifying law degree or an equivalent qualification.',
  },
  {
    icon: <AccountBalanceIcon sx={{ fontSize: 40, color: '#004AAD' }} />,
    title: 'SQE1',
    description: 'Pass the first stage of the Solicitors Qualifying Exam, focusing on legal knowledge.',
  },
  {
    icon: <AccountBalanceIcon sx={{ fontSize: 40, color: '#004AAD' }} />,
    title: 'SQE2',
    description: 'Pass the second stage of the SQE, testing practical skills and legal application.',
  },
  {
    icon: <WorkIcon sx={{ fontSize: 40, color: '#004AAD' }} />,
    title: 'Qualifying Work Experience',
    description: 'Complete 2 years of Qualifying Work Experience (QWE) in up to four legal settings.',
  },
  {
    icon: <CheckCircleIcon sx={{ fontSize: 40, color: '#004AAD' }} />,
    title: 'Suitability Requirements',
    description: 'Demonstrate you meet the SRA’s character and suitability requirements.',
  },
];


// Custom Stepper Component
const SolicitorStepper = () => (
  <Box sx={{ bgcolor: '#0F1214', py: 4 }}>
    <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'white', textAlign: 'center', mb: 3 }}>
      <Divider sx={{ borderBottomWidth: '5px', color: 'white' }}>Qualifying as a Solicitor in England & Wales</Divider>
    </Typography>
    <Grid container spacing={3} justifyContent="center">
      {STEPS.map((step, idx) => (
        <Grid item xs={12} md={3} textAlign="center" key={idx}>
          <Box>
            {step.icon}
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold', color: 'white' }}>
              {step.title}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
              {step.description}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
);

const FEATURES = [
  {
    icon: <WhatsAppIcon />,
    text: 'Access to a 24/7 WhatsApp group chat',
  },
  {
    icon: <SavingsIcon />,
    text: 'Affordable',
  },
  {
    icon: <EventAvailableIcon />,
    text: 'Flexible',
  },
];

const REVIEWS = [
  {
    quote: 'I want to extend my heartfelt thanks to Law School Compass for their exceptional SQE preparation material and support. The comprehensive resources, structured study plans, and insightful guidance have been instrumental in my preparation journey.',
    author: 'Sasmita',
    rating: 5,
  },
  {
    quote: 'Law School Compass is building a great community for those sitting the SQE to support one another and make the process far less daunting and lonely. I’ve read a few of these resources, and they are really good. I will definitely use them for my SQE preparation.',
    author: 'Maab (@lifewithmaab on insta)',
    rating: 5,
  },
];

const FeatureHighlights = () => (
  <Grid container spacing={2} justifyContent="center">
    {FEATURES.map((feature, idx) => (
      <Grid item xs={12} md={12} key={idx}>
        <Button
          fullWidth
          sx={{
            bgcolor: 'white',
            color: '#004AAD',
            borderRadius: '30px',
            py: 2,
            px: 3,
            fontSize: '1rem',
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            ':hover': { bgcolor: '#004AAD', color: 'white', borderColor:'white',  borderWidth: 3, },
            borderStyle: 'solid', borderColor:'#004AAD',  borderWidth: 3,
          }}
        >
          {React.cloneElement(feature.icon, { sx: { fontSize: 30 } })}
          {feature.text}
        </Button>
      </Grid>
    ))}
  </Grid>
);

const CustomerReviews = () => (
  <Box sx={{ bgcolor: '#0F1214', py: 4 }}>
    <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'white', textAlign: 'center', mb: 3 }}>
      <Divider sx={{ borderBottomWidth: '5px', color: 'white' }}>Customer Reviews</Divider>
    </Typography>
    <Grid container spacing={3} justifyContent="center">
      {REVIEWS.map((review, idx) => (
        <Grid item xs={12} md={5} key={idx}>
          <Card sx={{ bgcolor: '#14181B', color: 'white', p: 2, m: 1, borderRadius:'20px',borderStyle: 'solid', borderColor:'#004AAD' }}>
            <CardContent>
              <FormatQuoteIcon sx={{ fontSize: 90, color: '#004AAD' }} />
              <Typography variant="body1" sx={{ mt: 1 }}>
                {review.quote}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2, fontWeight: 'bold' }}>
                - {review.author}
              </Typography>
              <Rating
                value={review.rating}
                readOnly
                sx={{
                  mt: 2,
                  color: '#004AAD',
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
);

const Home = () => {
  return (
    <Box className="App" sx={{ bgcolor: '#0F1214', color: 'white' }}>
      <Box sx={{ py: 4, px:2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={7} textAlign="center" sx={{ p: 2 }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
              100% affordable, flexible support for the Solicitors Qualification Exam (SQE)
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Take the next step in your legal career from the comfort of your own home!
            </Typography>
            <Button
              component={RouterLink}
              to="/Signup"
              size="large"
              sx={{
                bgcolor: 'white',
                color: '#004AAD',
                borderRadius: '30px',
                px: 2,
                height: 50,
                ':hover': { bgcolor: '#004AAD', color: 'white' },
                borderStyle: 'solid', borderColor:'primary',  borderWidth: 3,
              }}
              endIcon={<ArrowForwardIcon />}
            >
              Start Free Today
            </Button>
            <Typography variant="h6" sx={{ mt: 3 }}>
              YOU ARE IN CONTROL.<br />
              ULTRA FLEXIBILITY IN LEARNING.<br />
              Study from anywhere.<br />
              TAKE THE NEXT STEPS IN YOUR JOURNEY TO QUALIFICATION.<br />
              And take your exams whenever you're ready.
            </Typography>
            <FeatureHighlights />
          </Grid>
          <Grid item xs={12} md={5} textAlign="center">
            <Box
              component="img"
              src={girlStudyingPolygon}
              alt="Girl Studying"
              sx={{
                width: '90%',
                maxWidth: 900,
                borderRadius: 2,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <SolicitorStepper />
      <CustomerReviews />
    </Box>
  );
};

export default Home;
