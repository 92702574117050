import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
// import { Link as SwitchLink} from "react-router-dom";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import SettingsIcon from '@mui/icons-material/Settings';
import QuizIcon from '@mui/icons-material/Quiz';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import WavingHandIcon from '@mui/icons-material/WavingHand';

import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import firebase from './firebase'; 
import './App.css';
import 'firebase/firestore';

import FLK1QuizPopUp from './FLK1QuizPopUp.js';
import FLK2QuizPopUp from './FLK2QuizPopUp.js';
import LLBQuizPopUp from './LLBQuizPopUp.js';

import FLK1MockPopUp from './FLK1MockPopUp.js';
import FLK2MockPopUp from './FLK2MockPopUp.js';

import FLK1Grid from './LearningMaterial/FLK1Grid.js';
import FLK2Grid from './LearningMaterial/FLK2Grid.js';
// import LLBGrid from './LearningMaterial/LLBGrid.js';


const QuizSection = () => {

  const [openFLK1QuizDialog, setOpenFLK1Quiz] = React.useState(false);
const handleOpenFLK1Quiz = () => {
  setOpenFLK1Quiz(true);
};
const [openFLK1MockDialog, setOpenFLK1Mock] = React.useState(false);
const handleOpenFLK1Mock = () => {
  setOpenFLK1Mock(true);
};
const [openFLK2MockDialog, setOpenFLK2Mock] = React.useState(false);
const handleOpenFLK2Mock = () => {
  setOpenFLK2Mock(true);
};
const [openFLK2QuizDialog, setOpenFLK2Quiz] = React.useState(false);
const handleOpenFLK2Quiz = () => {
  setOpenFLK2Quiz(true);
};
const [openLLBQuizDialog, setOpenLLBQuiz] = React.useState(false);
const handleOpenLLBQuiz = () => {
  setOpenLLBQuiz(true);
};
const [partOneAvailable, setAvailability] = React.useState('');

 React.useEffect(()=>{
   firebase.auth().onAuthStateChanged((user)=>{
     if(user){
       const userRef = firebase.database().ref("users/"+user.uid);
       userRef.on("value", (snapshot)=>{
         const userVal = snapshot.val()
         if(userVal){
          setAvailability(userVal.flk1Mock.partOneAvailable)
         }
       });
     }else{
      setAvailability('');
     }
   })
});

  // const mockButtons = [
  //   <Button key="one" onClick={partOneAvailable=="yes" ? handleOpenFLK1Mock : ()=>{window.location.href = 'https://buy.stripe.com/5kAeVra771AEaB228r'}}>FLK1</Button>,
  //   <Button key="two" >FLK2</Button>,
  // ];
    const mockButtons = [
    <Button key="one" onClick={handleOpenFLK1Mock}>FLK1</Button>,
    <Button key="two" onClick={handleOpenFLK2Mock}>FLK2</Button>,
  ];

  return (
    <div>
      <br/>
    <Grid container spacing={2}>
      <Grid item xs={6}>

      <Button 
                variant="contained" 
                color="primary" 
                onClick={handleOpenFLK1Quiz}
                sx={{
                  textTransform: 'none',
                  padding: '8px 16px',
                  borderRadius: '20px',
                }}
              >
                FLK1
                </Button>
      <FLK1QuizPopUp open={openFLK1QuizDialog} onClose={()=>setOpenFLK1Quiz(false)}/>
      </Grid>
      <Grid item xs={6}>

      <Button 
                variant="contained" 
                color="primary" 
                onClick={handleOpenFLK2Quiz}
                sx={{
                  textTransform: 'none',
                  padding: '8px 16px',
                  borderRadius: '20px',
                }}
              >
              FLK2
              </Button>
          <FLK2QuizPopUp open={openFLK2QuizDialog} onClose={()=>setOpenFLK2Quiz(false)}/>

      </Grid>
    </Grid>
    <br/>
    <br/>
    </div>
  );
};

const MockSection = () => {

  const [openFLK1QuizDialog, setOpenFLK1Quiz] = React.useState(false);
const handleOpenFLK1Quiz = () => {
  setOpenFLK1Quiz(true);
};
const [openFLK1MockDialog, setOpenFLK1Mock] = React.useState(false);
const handleOpenFLK1Mock = () => {
  setOpenFLK1Mock(true);
};
const [openFLK2MockDialog, setOpenFLK2Mock] = React.useState(false);
const handleOpenFLK2Mock = () => {
  setOpenFLK2Mock(true);
};
const [openFLK2QuizDialog, setOpenFLK2Quiz] = React.useState(false);
const handleOpenFLK2Quiz = () => {
  setOpenFLK2Quiz(true);
};
const [openLLBQuizDialog, setOpenLLBQuiz] = React.useState(false);
const handleOpenLLBQuiz = () => {
  setOpenLLBQuiz(true);
};
const [partOneAvailable, setAvailability] = React.useState('');

 React.useEffect(()=>{
   firebase.auth().onAuthStateChanged((user)=>{
     if(user){
       const userRef = firebase.database().ref("users/"+user.uid);
       userRef.on("value", (snapshot)=>{
         const userVal = snapshot.val()
         if(userVal){
          setAvailability(userVal.flk1Mock.partOneAvailable)
         }
       });
     }else{
      setAvailability('');
     }
   })
});

  // const mockButtons = [
  //   <Button key="one" onClick={partOneAvailable=="yes" ? handleOpenFLK1Mock : ()=>{window.location.href = 'https://buy.stripe.com/5kAeVra771AEaB228r'}}>FLK1</Button>,
  //   <Button key="two" >FLK2</Button>,
  // ];
    const mockButtons = [
    <Button key="one" onClick={handleOpenFLK1Mock}>FLK1</Button>,
    <Button key="two" onClick={handleOpenFLK2Mock}>FLK2</Button>,
  ];

  return (
    <div>
    <Grid container spacing={2}>
      <Grid item xs={6}>
      <Button 
                variant="contained" 
                color="primary" 
                onClick={handleOpenFLK1Mock}
                sx={{
                  textTransform: 'none',
                  padding: '8px 16px',
                  borderRadius: '20px',
                }}
              >FLK1</Button>

      <FLK1MockPopUp open={openFLK1MockDialog} onClose={()=>setOpenFLK1Mock(false)}/>

      </Grid>
      <Grid item xs={6}>

      <Button 
                variant="contained" 
                color="primary" 
                onClick={handleOpenFLK2Mock}
                sx={{
                  textTransform: 'none',
                  padding: '8px 16px',
                  borderRadius: '20px',
                }}
              >FLK2</Button>
                    <FLK2MockPopUp open={openFLK2MockDialog} onClose={()=>setOpenFLK2Mock(false)}/>


      </Grid>
    </Grid>

    <br/>
    <br/>
    <br/>
    <br/>

    </div>
  );
};

 const Settings = () => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const history = useHistory();

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  async function handleUpdateFirstName() {
    const user = firebase.auth().currentUser;
    await firebase.database().ref("users").child(user.uid).update({
      firstName: firstName
    });

    console.log('User profile updated successfully');
    // Reset input fields and error state
    setFirstName('');
  };
    async function handleUpdateLastName() {
      const user = firebase.auth().currentUser;
      await firebase.database().ref("users").child(user.uid).update({
        lastName: lastName
      })
      console.log('User profile updated successfully');
      // Reset input fields and error state
      setLastName('');
    };
    const handleDeleteAccount = async () => {
      try {
        // Delete the user from Firebase Authentication
        const user = firebase.auth().currentUser;
        await user.delete();
        alert('Your account had been deleted');
        console.log('account deleted');
        // Redirect to home page
        history.push('/');
      } catch (error) {
        alert('Error deleting account:', error);
        console.error('Error deleting account:', error);
      }
    };

  return (
    <div style={{backgroundColor:'#1C1F23', color:'white'}}>
      <Typography variant="h5" gutterBottom>
          Update Name
        </Typography>      
        <TextField
        id="firstName"
        label="First Name"
        variant="filled"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        fullWidth
        margin="normal"
        sx={{
          input: { color: 'white' }, // Set text color
          '& .MuiInputLabel-root': { color: 'white' }, // Set label color
          '& .MuiFilledInput-root': { backgroundColor: '#2C2F33' }, // Set background color
        }}
      />
            <Button variant="contained" color="primary" onClick={handleUpdateFirstName} sx={{borderRadius: '20px',}}>Update First Name</Button>

      <TextField
        id="lastName"
        label="Last Name"
        variant="filled"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        fullWidth
        margin="normal"
        sx={{
          input: { color: 'white' }, // Set text color
          '& .MuiInputLabel-root': { color: 'white' }, // Set label color
          '& .MuiFilledInput-root': { backgroundColor: '#2C2F33' }, // Set background color
        }}
      />
      <Button variant="contained" color="primary" onClick={handleUpdateLastName} sx={{borderRadius: '20px',}}>Update Last Name</Button>
      <br/>
      <br/>
      <Typography variant="h5" gutterBottom>
          Delete your Account
        </Typography>  
      <Button variant="contained" color="error" onClick={handleOpenDeleteDialog} sx={{borderRadius: '20px',}}>
        Delete Account
      </Button>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Are you sure you want to delete your account?</DialogTitle>
        <DialogContent>
          <p>This action cannot be undone.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} color="error">
            Yes, delete now
          </Button>
        </DialogActions>
      </Dialog>
    
    </div>
  );
};


const LearningMaterialSection = () => {
  const [userPurchasedProducts, setUserPurchasedProducts] = React.useState([]);
  const [planType, setPlanType] = React.useState('');

  React.useEffect(()=>{
    firebase.auth().onAuthStateChanged((user)=>{
      if(user){
        const userRef = firebase.database().ref("users/"+user.uid);
        userRef.on("value", (snapshot)=>{
          const userVal = snapshot.val()
          if(userVal){
            setPlanType(userVal.subscription.planType)
          }
        });
      }else{
        setPlanType('');
      }
    })
});

  // Function to fetch user's purchased products from Stripe
  async function fetchUserPurchasedProducts(stripeId) {
    try {
      const response = await fetch(`/api/user/purchased-products?stripeId=${stripeId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch user purchased products');
      }
      const data = await response.json();
      setUserPurchasedProducts(data);
    } catch (error) {
      console.error('Error fetching user purchased products:', error);
      throw error;
    }
  }

  React.useEffect(() => {
    // Fetch user's Stripe ID from Firestore
    const fetchStripeId = async () => {
      try {
        const user = firebase.auth().currentUser;
        if (!user) {
          throw new Error('User not authenticated');
        }

        const docRef = firebase.firestore().collection('customer').doc(user.uid);
        const docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
          const userData = docSnapshot.data();
          const stripeId = userData.stripeId;
          // Fetch user's purchased products from Stripe using the retrieved Stripe ID
          fetchUserPurchasedProducts(stripeId);
        } else {
          throw new Error('User data not found in Firestore');
        }
      } catch (error) {
        console.error('Error fetching user Stripe ID:', error);
      }
    };

    fetchStripeId();
  }, []);

  return (
    <Container maxWidth={false} sx={{ width: '100%' }}>
      <Grid container>
        <Grid item sm={6} sx={{padding:1}}>
      {/* Render Accordion for FLK1 */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="flk1-content"
          id="flk1-header"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography>FLK1</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            
            {planType === 'Free' ? 
            <div>
    <Grid container spacing={2} justifyContent="center">
      {/* First Row */}
      
      <Grid item xs={12} sm={6}>
        <Button fullWidth onClick={()=>window.location.href('https://buy.stripe.com/test_7sI28Gf8vdTke9a6oo')}>
          Business Finance
        </Button>
      </Grid>
 
      <Grid item xs={12} sm={3}>
        <Button fullWidth href="https://buy.stripe.com/test_cN23cK7G302u1moaEF">
          Business Law
        </Button>
      </Grid>
      
      {/* Second Row */}
      <Grid item xs={12} sm={3}>
        <Button fullWidth href="https://buy.stripe.com/test_aEU4gOe4rbLcd56bIK">
          Constitutional Law
        </Button>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button fullWidth href="https://buy.stripe.com/test_aEU14C9Ob8z0ghibIL">
          Contract Law
        </Button>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button fullWidth href="https://buy.stripe.com/test_4gweVsaSf02u5CE28c">
          Ethics
        </Button>
      </Grid>
      
      {/* Third Row */}
      <Grid item xs={12} sm={4}>
        <Button fullWidth href="https://buy.stripe.com/test_9AQ5kSgcz3eG3uw28d">
          Legal Service
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth to="https://buy.stripe.com/test_5kA5kS1hF9D4d568wC">
          Legal Systems
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth href="https://buy.stripe.com/test_fZe3cKf8vaH8d563cj">
          Tort Law
        </Button>
      </Grid>
    </Grid>
            </div>
            :
            <div>
   <FLK1Grid/>
            </div>
              }
          </Typography>
        </AccordionDetails>
      </Accordion>
      </Grid>

      <Grid item sm={6} sx={{padding:1}}>

      {/* Render Accordion for FLK2 */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="flk2-content"
          id="flk2-header"
        >
          <Typography>FLK2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            { planType === 'Free' ?
            <div>
    <Grid container spacing={2} justifyContent="center">
      {/* First Row */}
      <Grid item xs={12} sm={4}>
        <Button fullWidth href="https://buy.stripe.com/test_eVa00y6BZ7uW6GI7sG">
          Criminal Practice
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth href="https://buy.stripe.com/test_eVa4gO5xVbLcc1228l">
          Inheritance Tax
        </Button>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button fullWidth href="https://buy.stripe.com/test_cN2fZw4tR3eG4yA4gr">
          Land Law
        </Button>
      </Grid>
      
      {/* Second Row */}
      <Grid item xs={12} sm={3}>
        <Button fullWidth href="https://buy.stripe.com/test_aEUdRoaSfeXo3uwcMY">
          Criminal Law
        </Button>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button fullWidth href="https://buy.stripe.com/test_8wM6oW1hFaH8d56aEO">
          Solicitor Accounts
        </Button>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button fullWidth href="https://buy.stripe.com/test_7sI7t05xV16ye9aaEN">
          Trusts
        </Button>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Button fullWidth href="https://buy.stripe.com/test_28oeVs4tR6qSc12cMU">
          Wills And Administration Of Estates
        </Button>
      </Grid>
    </Grid>
            </div>
            :
            <div>
    <FLK2Grid/>
              </div>
              }
          </Typography>
        </AccordionDetails>
      </Accordion>

      </Grid>
      </Grid>
<br/>
<br/>
<br/>
<br/>
    </Container>
  );
};


const BookingSection = () => {
  function createData(packages, duration, price, url) {
    return { packages, duration, price, url };
  }
  
  const rows = [
    createData('1 hour session', '1 hour', '£25.50'),
    createData('Block of 5', '5x 1 hour', '£100.50'),
    createData('Block of 10', '10x 1 hour', '£200.50'),
  ];
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" gutterBottom>
          Tuition
        </Typography>
        <Typography variant="body2">
            book a session to get an in-depth learning experience
            </Typography>
            <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.packages}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th">
                {row.packages}
              </TableCell>
              <TableCell align="justify">{row.calories}</TableCell>
              <TableCell align="justify">{row.duration}</TableCell>
              <TableCell align="justify">{row.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </Grid>
      <Grid item xs={12} sm={6}>
      <Typography variant="h6" gutterBottom>
          Booking
        </Typography>
        <Paper>

          {/*
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar />
    </LocalizationProvider>
    */}
    <iframe
      id="JotFormIFrame-241272513936052"
      title="Law School Compass - Tuition request "
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allow="fullscreen"
      src="https://form.jotform.com/241272513936052"
      frameborder="0"
      style={{minWidth:"100%",maxWidth:"100%",minHeight:"300px", maxHeight:'600px',border:"none"}}
      scrolling="yes"
    >
    </iframe>
    <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
    <script>window.jotformEmbedHandler("iframe[id='JotFormIFrame-241272513936052']", "https://form.jotform.com/")</script>
        </Paper>
      </Grid>
    </Grid>
  );
};


const Dashboard = () => {
  const [value, setValue] = React.useState(0);
  const [planType, setPlanType] = React.useState('');
  const [userName, setUserName] = React.useState('');
 
   React.useEffect(()=>{
     firebase.auth().onAuthStateChanged((user)=>{
       if(user){
         const userRef = firebase.database().ref("users/"+user.uid);
         userRef.on("value", (snapshot)=>{
           const userVal = snapshot.val()
           if(userVal){
             setPlanType(userVal.subscription.planType)
           }
         });
       }else{
         setPlanType('');
       }
     })
 });
 React.useEffect(() => {
  // Fetch user data from Firebase
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    const userRef = firebase.database().ref("users/" + currentUser.uid);
    userRef.on("value", (snapshot) => {
      const userData = snapshot.val();
      if (userData) {
        setUserName(userData.firstName); // Set user's first name
      }
    });
  }
}, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [openSnackbar, setOpenSnackbar] = React.useState(true);

  // Function to handle closing the Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className="text-center" style={{backgroundColor:'#0F1214', color:'white'}}>
      <br/>
      <Typography gutterBottom variant="h3" component="div" sx={{ fontWeight: 'bold' }}>Dashboard</Typography>
      <Typography gutterBottom variant="h6" component="div" sx={{}}>Welcome Back {userName} <WavingHandIcon/> </Typography>
      <Grid container>
      <Grid item xs={12} >
      <Card
                elevation={4}
                style={{ 
                  width:'95%',
                  backgroundImage: planType === "Gold" ? "linear-gradient(to right, #E0AA3E, #F2DE7A, #E0AA3E, #E0AA3E)" :
                  planType === "Silver" ? "linear-gradient(to right, #B4B4B4, #FFFFFF, #B4B4B4, #B4B4B4)" :
                  planType === "Bronze Revision" ? "linear-gradient(to right, #CF603D, #F2BA7A, #CF603D, #CF603D)" :
                  planType === "Bronze Practice" ? "linear-gradient(to right, #CF603D, #F2BA7A, #CF603D, #CF603D)" :
                  planType === "Free" ? "linear-gradient(to right, #004AAD, #0064EA, #004AAD, #004AAD)" :
                  "linear-gradient(to right, #004AAD, #0064EA, #004AAD, #004AAD)",
                  color: planType === "Free" ? "white" :
                  "black",
                  fontWeight:'normal',
                  padding: 10,
                  height: '100%',
                  animation: 'shimmer 600s linear infinite both',
                  margin:'auto',
                  borderRadius: '20px',
                  
                }}
              >
                <CardContent>
                <Typography variant="h5" gutterBottom style={{fontWeight: 'bold' }}>
                  Your Subscription
                </Typography>
                <Typography variant="body1" style={{fontWeight:'normal',}}>
                  Enjoy the current {planType} Account you have, or change to a subscription that suits you better!
                </Typography>
                </CardContent>
                <Button
                style={{backgroundColor:'rgba(255,255,255,0.75)',display:'block',color:'black', fontWeight:'bold', size:'large', margin: 'auto', width:'37%', borderRadius: '20px',}} 
                component={RouterLink} to={'/MySubscription'}>
                Change your subcription
                </Button>
                <br/>
        </Card>
        </Grid>
        </Grid>

      <br/>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs 
        value={value} 
        onChange={handleChange} 
        centered 
        sx={{ color: 'white', bgcolor: '#14181B' }}
        textColor="inherit"
        indicatorColor="primary"
      >
        <Tab 
          label="Settings" 
          icon={<SettingsIcon />} 
          sx={{ flex: 1, minWidth: '15%', color: 'white' }} 
        />
        <Tab 
          label="Quiz" 
          icon={<QuizIcon />} 
          sx={{ flex: 1, minWidth: '15%', color: 'white' }} 
        />
        <Tab 
          label="Mock" 
          icon={<HourglassEmptyIcon />} 
          sx={{ flex: 1, minWidth: '20%', color: 'white' }} 
        />
        <Tab 
          label="Textbooks" 
          icon={<MenuBookIcon />} 
          sx={{ flex: 1, minWidth: '20%', color: 'white' }} 
        />
        <Tab 
          label="Tuition & Bookings" 
          icon={<EventAvailableIcon />} 
          sx={{ flex: 1, minWidth: '20%', color: 'white' }} 
          disabled
        />
      </Tabs>
    </Box>
      <TabPanel value={value} index={0} style={{backgroundColor:'#1C1F23'}}>
      <Typography variant="h5" gutterBottom style={{fontWeight: 'bold' }}>
          Settings
        </Typography>
        <Settings/>
      </TabPanel>
      <TabPanel value={value} index={1} style={{backgroundColor:'#1C1F23'}}>
      <Typography variant="h5" gutterBottom style={{fontWeight: 'bold' }}>
          Quiz
        </Typography>
        <Typography variant="body2">
        Attempt a multiple choice quiz, with no timer, to test your knowledge.
            Decide an area and topic, then you will have the option to select how many questions will be in the quiz:
            </Typography>
        <QuizSection />
      </TabPanel>
      <TabPanel value={value} index={2} style={{backgroundColor:'#1C1F23'}}>
      <Typography variant="h5" gutterBottom style={{fontWeight: 'bold' }}>
          Mock
        </Typography>
        <Typography variant="body2">
            Attempt a 180 min mock exam, to test your knowledge.
            Decide an area you want to do:
            </Typography>
        <MockSection />
      </TabPanel>
      <TabPanel value={value} index={3} style={{backgroundColor:'#1C1F23'}}>
      <Typography variant="h5" gutterBottom style={{fontWeight: 'bold' }}>
          Your Learning Material
        </Typography>
        <LearningMaterialSection />
      </TabPanel>
      <TabPanel value={value} index={4} style={{backgroundColor:'#1C1F23'}} disabled>
      <Typography variant="h5" gutterBottom style={{fontWeight: 'bold' }}>
          Tuition & Bookings (coming soon)
        </Typography>
        <BookingSection />
      </TabPanel>
<br/>
<Snackbar
        open={openSnackbar}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="info">
          If you made any purchases, give it some time for the perks to come through. It shouldn't take too long.
        </Alert>
      </Snackbar>

    </div>
  );
};

export default Dashboard;
